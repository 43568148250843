import React, { Component } from 'react'
import classNames from 'classnames'
import { Auth } from 'aws-amplify'
import packageJson from '../../package.json'
import { UserConsumer } from './context/UserContext'
import { capitalize } from '../utils/string'

export class AppInlineProfile extends Component {
  constructor() {
    super()
    this.state = {
      expanded: false
    }
    this.onClick = this.onClick.bind(this)
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded })
    event.preventDefault()
  }

  signOut() {
    Auth.signOut()
      .then(() => window.location.reload())
      .catch(err => console.log(err))
  }

  render() {
    return (
      <div>
        <div className={classNames('profile', { 'profile-expanded': this.state.expanded })}>
          <button className='p-link' onClick={this.onClick}>
            <UserConsumer>
              {context => (
                <span>
                  <span className='profile-name'>{context.fullName}</span>
                  <i className='fa fa-fw fa-caret-down' />
                  <span className='profile-role'>{context.groups && capitalize(context.groups[0])}</span>
                </span>
              )}
            </UserConsumer>
          </button>
        </div>

        <ul className='layout-menu profile-menu'>
          <li role='menuitem'>
            <button className='p-link' tabIndex={this.state.expanded ? null : '-1'}>
              <i className='fa fa-fw fa-user' />
              <span>Profile</span>
            </button>
            <div className='layout-menu-tooltip'>
              <div className='layout-menu-tooltip-arrow' />
              <div className='layout-menu-tooltip-text'>Profile</div>
            </div>
          </li>
          {/* <li role="menuitem">
                            <button className="p-link"  tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="fa fa-fw fa-user-secret"></i>
                                <span>Privacy</span>
                            </button>
                            <div className="layout-menu-tooltip">
                                <div className="layout-menu-tooltip-arrow"></div>
                                <div className="layout-menu-tooltip-text">Privacy</div>
                            </div>
                        </li> */}
          <li role='menuitem'>
            <button className='p-link' tabIndex={this.state.expanded ? null : '-1'}>
              <i className='fa fa-fw fa-cog' />
              <span>Settings</span>
            </button>
            <div className='layout-menu-tooltip'>
              <div className='layout-menu-tooltip-arrow' />
              <div className='layout-menu-tooltip-text'>Settings</div>
            </div>
          </li>
          <li role='menuitem'>
            <button onClick={this.signOut} className='p-link' tabIndex={this.state.expanded ? null : '-1'}>
              <i className='fa fa-fw fa-sign-out' />
              <span>Logout</span>
            </button>
            <div className='layout-menu-tooltip'>
              <div className='layout-menu-tooltip-arrow' />
              <div className='layout-menu-tooltip-text'>Logout</div>
            </div>
          </li>
          <li>
            <span className='versionNumber'>v{packageJson.version}</span>
          </li>
        </ul>
      </div>
    )
  }
}
