import React from 'react'

const NotAuthorizedPage = () => {
  return (
    <div className='ui-g ui-fluid'>
      <div className='ui-g-12 card card-w-title'>
        You don't have the required permissions to view this component. Please contact your supervisor.
      </div>
    </div>
  )
}

export default NotAuthorizedPage
