import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { Growl } from 'primereact/growl'

import './NewMessage.css'

const NewMessage = props => {
  const default_recipients = [{ label: 'John D.', value: 'JohnD' }, { label: 'Jane D.', value: 'JaneD' }]

  const [selRecipient, setSelRecipient] = useState({})
  const [msg, setMsg] = useState('')
  const [growlRef, setGrowlRef] = useState()

  const onSave = () => {
    growlRef.show({ severity: 'success', life: 2000, summary: 'New Message', detail: 'Message saved!' })
    setMsg('')
    setSelRecipient({})
    props.onClose()
  }

  useEffect(() => {}, [])

  return (
    <span className='NewMessageContainer'>
      <Growl ref={el => setGrowlRef(el)} />

      <Dialog
        header={
          <span>
            <i className='fa fa-envelope-o' />
            &nbsp;&nbsp; Enter a message
          </span>
        }
        footer={
          <span className='newMessageFooter'>
            <Button label='Save' onClick={onSave} className='p-button-secondary' />
            <Button label='Cancel' onClick={props.onClose} className='p-button-secondary' />
          </span>
        }
        className='newMsgDialog'
        visible={props.visible}
        modal={true}
        onHide={props.onClose}
        positionTop={props.position.top}
        positionLeft={props.position.left}
      >
        <Dropdown
          value={selRecipient}
          options={default_recipients}
          onChange={e => {
            setSelRecipient(e.value)
          }}
          style={{ width: '165px' }}
          placeholder='Select a recipient:'
        />

        <br />
        <br />

        <InputTextarea rows={15} cols={72} value={msg} onChange={e => setMsg(e.target.value)} />
      </Dialog>
    </span>
  )
}

NewMessage.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired
}

export default NewMessage
