// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket: 'asgdev',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'http://asgdev.s3-website-us-east-1.amazonaws.com',
  aws_cognito_identity_pool_id: 'us-east-1:a523e3bd-bbe2-46a0-8d58-1fd1a085ad4b',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_Vp2i32Q9A',
  aws_user_pools_web_client_id: '7or3vakhseo6p9m00kkfj54pqu'
}

export default awsmobile
