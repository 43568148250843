import React, { Component } from 'react'

import App from './App'
import { UserProvider } from './context/UserContext'
import { Auth } from 'aws-amplify'
import Amplify from 'aws-amplify'
import aws_exports from '../aws-exports'
import { withAuthenticator } from 'aws-amplify-react'

Amplify.configure(aws_exports)

class AppWrapper extends Component {
  constructor() {
    super()
    this.state = {
      user: {}
    }
  }

  componentDidMount() {
    if (!this.state.user.hasOwnProperty('signInUserSession')) {
      Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then(u => {
          this.setState(
            {
              user: {
                groups: u.signInUserSession.accessToken.payload['cognito:groups'],
                userName: u.attributes.email,
                fullName: `${u.attributes.name}`
              }
            },
            () => {
              // console.log(this.state.user)
            }
          )
        })
        .catch(err => console.log(err))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <UserProvider value={this.state.user}>
        <App />
      </UserProvider>
    )
  }
}

// export default withAuthenticator(App, true);
export default withAuthenticator(AppWrapper, true)
