import React, { lazy, Suspense } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { UserConsumer } from './context/UserContext'
import ReportsPage from './containers/ReportsPage'
import NotAuthorizedPage from './containers/NotAuthorizedPage'

export const AppRouter = () => {
  const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '../components/Dashboard/Dashboard'))
  const DirectorDashboardPage = lazy(() =>
    import(/* webpackChunkName: "DirectorDashboardPage" */ './containers/DirectorDashboardPage')
  )
  const ClaimantPage = lazy(() => import(/* webpackChunkName: "ClaimantPage" */ './Claimant/ClaimantPage'))
  const VendorWorkPage = lazy(() => import(/* webpackChunkName: "VendorWorkPage" */ './containers/VendorWorkPage'))
  const ReferralPage = withRouter(
    lazy(() => import(/* webpackChunkName: "ReferralPage" */ './containers/ReferralPage'))
  )
  const OpenReferralsPage = lazy(() =>
    import(/* webpackChunkName: "OpenReferralsPage" */ './containers/OpenReferralsPage')
  )
  const NewReferralPage = lazy(() => import(/* webpackChunkName: "NewReferralPage" */ './containers/NewReferralPage'))
  const TimelineDetailPage = lazy(() =>
    import(/* webpackChunkName: "TimelineDetailPage" */ './containers/TimelineDetailPage')
  )
  const VendorMarketplacePage = lazy(() =>
    import(/* webpackChunkName: "VendorMarketplacePage" */ './containers/VendorMarketplacePage')
  )
  const InvoicingPage = lazy(() => import(/* webpackChunkName: "InvoicingPage" */ './containers/InvoicingPage'))
  const EventsPage = lazy(() => import(/* webpackChunkName: "EventsPage" */ './containers/EventsPage'))
  const NewClaim = lazy(() => import(/* webpackChunkName: "EventsPage" */ './NewClaim/NewClaim.js'))
  const ClaimPayment = lazy(() => import(/*webpackChunkName: "ClaimPayment" */ './Claims/ClaimPayment/ClaimPayment.js'))
  const Diaries = lazy(() => import(/*webpackChunkName: "Diaries" */ '../components/Diaries/Diaries'))

  const LossRatioMaster = lazy(() => import('../components/LossRatioMaster'))
  const LossRunUpload = lazy(() => import('../components/LossRunUpload'))

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path='/' exact>
          <Dashboard />
        </Route>
        <Route path='/dashboard'>
          <Dashboard />
        </Route>
        <Route path='/claimant/:id'>
          <ClaimantPage />
        </Route>
        <Route path='/referrals'>
          <OpenReferralsPage />
        </Route>
        <Route path='/referral/:id' component={ReferralPage} />
        {/* <ReferralPage /> */}
        {/* </Route> */}
        <Route path='/newref'>
          <NewReferralPage />
        </Route>
        <Route path='/timeline/:id'>
          <TimelineDetailPage />
        </Route>
        <Route path='/reports'>
          <ReportsPage />
        </Route>
        <Route path='/events'>
          <EventsPage />
        </Route>
        <Route path='/claim/new'>
          <NewClaim />
        </Route>
        <Route path='/claim/payment'>
          <ClaimPayment />
        </Route>
        <Route path='/diaries'>
          <Diaries />
        </Route>
        <Route path='/lossratiomaster'>
          <LossRatioMaster />
        </Route>
        <Route path='/lossrunupload'>
          <LossRunUpload />
        </Route>
      </Switch>

      <UserConsumer>
        {(context) => {
          if (context && context.groups && context.groups.includes('director')) {
            return (
              <Switch>
                <Route path='/director'>
                  <DirectorDashboardPage />
                </Route>
                <Route path='/invoicing'>
                  <InvoicingPage />
                </Route>
                <Route path='/vendormarket'>
                  <VendorMarketplacePage />
                </Route>
                <Route path='/vendorWork'>
                  <VendorWorkPage />
                </Route>
              </Switch>
            )
          } else {
            return (
              <Switch>
                <Route path='/director'>
                  <NotAuthorizedPage />
                </Route>
                <Route path='/invoicing'>
                  <NotAuthorizedPage />
                </Route>
                <Route path='/vendormarket'>
                  <NotAuthorizedPage />
                </Route>
                <Route path='/vendorWork'>
                  <NotAuthorizedPage />
                </Route>
              </Switch>
            )
          }
        }}
      </UserConsumer>
    </Suspense>
  )
}

export default AppRouter
