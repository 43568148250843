import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import logo from '../assets/images/Asgard.png'
import corvelLogo from '../assets/images/corvelLogo.png'
import deltaLogo from '../assets/images/deltaLogo.png'
import integralLogo from '../assets/images/integralLogo.png'
import asgLogoSmall from '../assets/images/asgLogoSmall.png'

export class AppTopbar extends Component {
  static defaultProps = {
    onMenuButtonClick: null,
    onTopbarMenuButtonClick: null,
    onTopbarItemClick: null,
    profileMode: null,
    horizontal: false,
    topbarMenuActive: false,
    activeTopbarItem: null
  }

  static propTypes = {
    onMenuButtonClick: PropTypes.func.isRequired,
    onTopbarMenuButtonClick: PropTypes.func.isRequired,
    onTopbarItemClick: PropTypes.func.isRequired,
    profileMode: PropTypes.string.isRequired,
    horizontal: PropTypes.bool.isRequired,
    topbarMenuActive: PropTypes.bool.isRequired,
    activeTopbarItem: PropTypes.string
  }

  constructor() {
    super()
    this.state = {}
  }

  onTopbarItemClick(event, item) {
    if (this.props.onTopbarItemClick) {
      this.props.onTopbarItemClick({
        originalEvent: event,
        item: item
      })
    }
  }

  render() {
    let topbarItemsClassName = classNames('topbar-items fadeInDown', {
      'topbar-items-visible': this.props.topbarMenuActive
    })

    return (
      <div className='topbar clearfix'>
        <div className='topbar-left'>
          <img alt='Logo' src={logo} className='topbar-logo' />
        </div>

        <div className='topbar-right'>
          <button className='p-link' id='menu-button' onClick={this.props.onMenuButtonClick}>
            <i className='fa fa-angle-left' />
          </button>

          <button className='p-link' id='topbar-menu-button' onClick={this.props.onTopbarMenuButtonClick}>
            <i className='fa fa-bars' />
          </button>
          <ul className={topbarItemsClassName}>
            {(this.props.profileMode === 'top' || this.props.horizontal) && (
              <li
                className={classNames('profile-item', { 'active-top-menu': this.props.activeTopbarItem === 'profile' })}
                onClick={e => this.onTopbarItemClick(e, 'profile')}
              >
                <button className='p-link'>
                  <img alt='User' className='profile-image' src='assets/layout/images/avatar.png' />
                  <span className='topbar-item-name'>Isabel Lopez</span>
                  <span className='topbar-item-role'>Marketing</span>
                </button>

                <ul className='layout-menu fadeInDown'>
                  <li role='menuitem'>
                    <button className='p-link'>
                      <i className='fa fa-fw fa-user' />
                      <span>Profile</span>
                    </button>
                  </li>
                  <li role='menuitem'>
                    <button className='p-link'>
                      <i className='fa fa-fw fa-user-secret' />
                      <span>Privacy</span>
                    </button>
                  </li>
                  <li role='menuitem'>
                    <button className='p-link'>
                      <i className='fa fa-fw fa-cog' />
                      <span>Settings</span>
                    </button>
                  </li>
                  <li role='menuitem'>
                    <button className='p-link'>
                      <i className='fa fa-fw fa-sign-out' />
                      <span>Logout</span>
                    </button>
                  </li>
                </ul>
              </li>
            )}

            <li
              className={classNames({ 'active-top-menu': this.props.activeTopbarItem === 'messages' })}
              onClick={e => this.onTopbarItemClick(e, 'messages')}
            >
              <button className='p-link'>
                <i className='topbar-icon fa fa-fw fa-envelope-o' />
                <span className='topbar-badge'>5</span>
                <span className='topbar-item-name'>Messages</span>
              </button>
              <ul className='messagesMenu layout-menu fadeInDown'>
                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 1' src={integralLogo} width='35' />
                    <span className='mainNavMessageMenuText'>Claimant Video Exam Notice</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 2' src={deltaLogo} width='35' />
                    <span className='mainNavMessageMenuText'>IW Not Viewed at Listed Locations</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 3' src={corvelLogo} width='35' />
                    <span className='mainNavMessageMenuText'>Denial Code Needed for Claim</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 4' src={asgLogoSmall} width='35' />
                    <span className='mainNavMessageMenuText'>SLA Threshhold Approaching</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 5' src={integralLogo} width='35' />
                    <span className='mainNavMessageMenuText'>Urgent: Claimant RSD Finding</span>
                  </button>
                </li>

                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 5' src={corvelLogo} width='35' />
                    <span className='mainNavMessageMenuText'>Case Management Update</span>
                  </button>
                </li>

                <li role='menuitem'>
                  <button className='p-link topbar-message'>
                    <img alt='Avatar 5' src={asgLogoSmall} width='35' />
                    <span className='mainNavMessageMenuText'>Updated SLAC Score: ABC Company</span>
                  </button>
                </li>
              </ul>
            </li>

            <li
              className={classNames({ 'active-top-menu': this.props.activeTopbarItem === 'notifications' })}
              onClick={e => this.onTopbarItemClick(e, 'notifications')}
            >
              <button className='p-link'>
                <i className='topbar-icon fa fa-fw fa-bell-o' />
                <span className='topbar-badge animated rubberBand'>4</span>
                <span className='topbar-item-name'>Notifications</span>
              </button>
              <ul className='layout-menu fadeInDown'>
                <li role='menuitem'>
                  <button className='p-link'>
                    <i className='fa fa-fw fa-file-pdf-o' />
                    <span>3 new documents</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link'>
                    <i className='fa fa-fw fa-calendar-check-o' />
                    <span>1 upcoming appointment</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link'>
                    <i className='fa fa-fw fa-usd' />
                    <span>8 new bills for review</span>
                  </button>
                </li>
                <li role='menuitem'>
                  <button className='p-link'>
                    <i className='fa fa-fw fa-clock-o' />
                    <span>2 overdue reports</span>
                  </button>
                </li>
              </ul>
            </li>
            <li
              className={classNames('search-item', { 'active-top-menu': this.props.activeTopbarItem === 'search' })}
              onClick={e => this.onTopbarItemClick(e, 'search')}
            >
              <div className='topbar-search'>
                <input type='text' placeholder='Search' />
                <i className='fa fa-search' />
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
