import React from "react";
// import VendorMarketplaceForm from "../forms/VendorMarketplaceForm";
import { Link } from "react-router-dom";
import "./ReportsPage.css";

class ReportsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      vendors: []
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="ui-g ui-fluid">
        <div className="ui-g-12 card card-w-title">
          <h2>Reports</h2>
          <hr />

          <ul className="reportList">
            <li className="reportListItem">
              <Link to={"/reports/claimsCost"}>Claims Cost Analysis</Link>
            </li>
            <li className="reportListItem">
              <Link to={"/reports/vendorCost"}>Vendor Cost Analysis</Link>
            </li>
            <li className="reportListItem">
              <Link to={"/reports/vendorPerformance"}>Vendor Performance</Link>
            </li>
            <li className="reportListItem">
              <Link to={"/reports/overdueReferrals"}>Overdue Referrals</Link>
            </li>
            <li className="reportListItem">
              <Link to={"/reports/openClaims"}>Open Claims</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ReportsPage;
